module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tokinfo | Express Anything With Numbers","short_name":"Tokinfo","start_url":"/","background_color":"#fff","theme_color":"#717171","display":"minimal-ui","icon":"src/images/favicon.ico","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8aefd1324367a3406021e8f5ac2bf237"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171636438-1","head":false,"cookieDomain":"www.tokinfo.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
